import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchBusinesses, fetchVndorPayouts } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { RidesSection } from "../style/rides";
import { VendorPayoutsTable } from "../components/VendorPayoutsTable";
import { BallBeat } from "react-pure-loaders";
import { VendorPayoutsForm } from "../components/VendorPayoutsForm";
import { VendorPayoutStats } from "../components/VendorPayoutStats";
function VendorPayoutsContainer({ businessesData, fetchBusinesses, payouts }) {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (businessesData.businesses.length === 0) {
      fetchBusinesses();
    }
  }, [fetchBusinesses]);
  // console.log(state, ridesFlag);

  return (
    <>
      <GlobalStyle />
      <Layout>
        <RidesSection>
          <h2>Vendor Payouts List</h2>
          <br />
          <VendorPayoutsForm businessesData={businessesData.businesses} />
          {businessesData.loadingpayouts ? (
            <h3>
              Loading Vendor Payouts{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={businessesData.loadingpayouts}
                />
              </span>{" "}
            </h3>
          ) : businessesData.errorpayouts ? (
            <>
              <h3>ERROR ⚠️ FETCHING Vendor Payouts</h3>
              <br />
              <p style={{ color: "grey" }}>{businessesData.errorpayouts}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              {payouts.length > 0 ? (
                <>
                  <VendorPayoutStats
                    payouts={payouts}
                    vendor={businessesData.vendor}
                  />
                  <VendorPayoutsTable
                    payouts={payouts}
                    vendor={businessesData.vendor}
                  />
                </>
              ) : (
                "No vendor payouts found ⚠️"
              )}
            </>
          )}
        </RidesSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    businessesData: state.vendors,
    payouts: state.vendors.payouts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBusinesses: () => dispatch(fetchBusinesses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorPayoutsContainer);
