import React, { useState } from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";
import { exportCSVFile } from "../../jsonToCsvDownload";
export const VendorPayoutsTable = ({ payouts, vendor }) => {
  // const handleDownload = (e) => {
  //   e.preventDefault();
  //   exportCSVFile("")
  // };
  const columns = React.useMemo(
    () => [
      {
        Header: "S No",
        id: "row",
        filterable: false,
        accessor: (payout) => {
          var rowNum = payouts.indexOf(payout) + 1;
          // console.log(rowNum);
          return rowNum;
        },
      },

      // {
      //     id: "checkbox",
      //     accessor: "",
      //     filterable: false,
      //     Cell: ({ original }) => {
      //         console.log("original", original)
      //         return (
      //             <input
      //                 type="checkbox"
      //                 className="checkbox"
      //                 checked={this.state.selected[original.bid_id] === true}
      //                 onChange={() => this.toggleRow(original.bid_id)}
      //             />
      //         );
      //     },
      //     Header: x => {
      //         return (
      //             <input
      //                 type="checkbox"
      //                 className="checkbox"
      //                 checked={this.state.selectAll === 1}
      //                 ref={input => {
      //                     if (input) {
      //                         input.indeterminate = this.state.selectAll === 2;
      //                     }
      //                 }}
      //                 onChange={() => this.toggleSelectAll()}
      //             />
      //         );
      //     },
      //     sortable: false,
      //     width: 45

      // },
      {
        Header: "Trip Id",
        accessor: "bid_id",
      },
      {
        Header: "Vendor Id",
        accessor: (payout) => payout.live_api_booking_id || "NA",
        // minWidth: 150
      },

      {
        Header: "Base Fare",
        accessor: (data) =>
          data.actual_ride_amount === null || data.actual_ride_amount === 0
            ? data.ride_amount !== null
              ? data.ride_amount.toFixed(1)
              : 0
            : data.actual_ride_amount.toFixed(1),
        id: 3,
      },

      // {
      // 	Header: "Deposit",
      // 	accessor: "pricing.rideAmount"
      // },
      {
        Header: "Driv./ Deliv. Chrgs",
        accessor: (payout) =>
          (payout.driver_charges && payout.driver_charges.toFixed(1)) || 0,
        // minWidth: 150
      },
      {
        Header: "Ser Tax",
        accessor: (data) =>
          data.actual_service_tax === 0 || data.actual_service_tax === null
            ? (data.service_tax && data.service_tax.toFixed(1)) || 0
            : data.actual_service_tax.toFixed(1),
        id: 5,
      },

      {
        Header: "Initial Online Adv.",
        accessor: (payout) =>
          (payout.advance_amount && payout.advance_amount.toFixed(1)) || 0,
      },
      {
        Header: "Discount",
        accessor: (payout) => payout.sumofdiscountandredeemed_points || 0,
        // minWidth: 150
      },
      // {
      //   Header: "Disc + Red Pts",
      //   accessor: "",
      //   // minWidth: 150
      // },
      {
        Header: "Final Adv Amt",
        // accessor: "actual_advance_amount",
        accessor: (payout) =>
          (payout.final_advance_amount &&
            payout.final_advance_amount.toFixed(1)) ||
          0,
        // minWidth: 150
      },
      {
        Header: "Night/ Security Chrgs",
        // accessor: "actual_advance_amount",
        accessor: (payout) =>
          (payout.night_charges && payout.night_charges.toFixed(1)) || 0,
        // minWidth: 150
      },
      // {
      //   Header: "Dpst Refund",
      //   // accessor: "actual_advance_amount",
      //   accessor: (payout) =>
      //     (payout.deposit_refund && payout.deposit_refund.toFixed(1)) || 0,
      //   // minWidth: 150
      // },
      // {
      //     Header: "Total Fare",
      //     accessor: "totalBillAmount",
      // },

      // {
      //   Header: "Final Pay",
      //   accessor: (data) =>
      //     data.actual_total_bill_amount === 0 ||
      //     data.actual_total_bill_amount === null
      //       ? data.total_bill_amount.toFixed(1)
      //       : data.actual_total_bill_amount.toFixed(1),
      //   id: 4,
      //   // minWidth: 150
      // },

      {
        Header: "Vendor Pay",
        accessor: (p) =>
          (p.final_vendor_payback && p.final_vendor_payback.toFixed(1)) || 0,
        id: 2,
        // minWidth: 150
      },
      {
        Header: "Zyppys Mar.",
        accessor: (p) => (p.zyppys_margin && p.zyppys_margin.toFixed(1)) || 0,
        id: 1,
        // minWidth: 150
      },
      {
        Header: "Vendor",
        accessor: "vendor_name",
        maxWidth: 110,
      },
      {
        Header: "Traveler",
        accessor: "traveler_name",
        maxWidth: 110,
      },
      {
        Header: "From City",
        accessor: "from_city",
        maxWidth: 110,
      },
      {
        Header: "End Date",
        accessor: (vp) => vp.ride_end_date.split(" ")[0],
        maxWidth: 110,
      },
    ],
    []
  );

  const drivenColumns = React.useMemo(() => [
    {
      Header: "S No",
      id: "row",
      filterable: false,
      accessor: (payout) => {
        var rowNum = payouts.indexOf(payout) + 1;
        // console.log(rowNum);
        return rowNum;
      },
    },
    {
      Header: "Trip ID",
      accessor: "bid_id",
    },
    {
      Header: "Amount",
      accessor: (payout) => (payout.amount && payout.amount.toFixed(1)) || 0,
    },
    // {
    //   Header: "Actual Amount",
    //   accessor: (payout) => payout.actual_total_bill_amount || 0,
    // },
    {
      Header: "Security Depst.",
      accessor: (payout) =>
        (payout.security_deposit && payout.security_deposit.toFixed(1)) || 0,
    },
    {
      Header: "Discount Amt.",
      accessor: (payout) =>
        (payout.discount_amount && payout.discount_amount.toFixed(1)) || 0,
    },
    // {
    //   Header: "Refunded Amt.",
    //   accessor: (payout) => payout.refunded_amount || 0,
    // },
    {
      Header: "Final Refund",
      accessor: (payout) =>
        (payout.final_refund && payout.final_refund.toFixed(1)) || 0,
    },
  ]);

  var data = React.useMemo(() => payouts, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      //   path={"/ride"}
      // pass filename to enable .csv download
      filename={vendor}
      extras={true}
      vp={true}
      columns={vendor === "Driven" ? drivenColumns : columns}
      data={data}
    />
  );
  // console.log(vendorName.split(" ").join("_"));
  return (
    <>
      <TableStyles extras={true} vp={true}>
        {payouts ? tableRender : " "}
      </TableStyles>
    </>
  );
};
